<template lang="pug">
TableV2.mx-3(
  :isViewAdd="false"
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getDate"
  :isLoading="isLoading",
  :actions="actions"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'number', text: this.$i18n.t('tableHeaders.number'), sortable: false },
        { value: 'type_document', text: this.$i18n.t('tableHeaders.type_document'), sortable: false },
        { value: 'rank', text: this.$i18n.t('tableHeaders.rank'), sortable: false },
        { value: 'position', text: this.$i18n.t('tableHeaders.position'), sortable: false },
        { value: 'port', text: this.$i18n.t('tableHeaders.port'), sortable: false },
        { value: 'status_document', text: this.$i18n.t('tableHeaders.status_document'), sortable: false },
        { value: 'event', text: this.$i18n.t('actions'), sortable: false }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToDocument(item), color: 'blue', name: 'mdi-information-outline', isView: true }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.sailor.isLoading,
      items: state => state.sailor.replacementStatements
    })
  },
  methods: {
    ...mapActions(['getReplacementStatementsSailor']),
    async getDate (params) {
      await this.getReplacementStatementsSailor({ ...this.$route.params, params })
    },
    goToDocument (item) {
      this.$router.push({ name: 'qualification-replacement-statement-info', params: { statementId: item.id } })
    }
  }
}
</script>
